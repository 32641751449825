import Axios from 'axios';

const axios = Axios.create();


const img = 'https://cdn-icons-png.flaticon.com/512/194/194279.png'
export const getCertificate = (id) => axios.get(`https://jsonplaceholder.typicode.com/photos`)
  .then(resp => {
    console.log('id',img);
    if (resp && resp.data) return img ;
    return Promise.reject(new Error('Error while connecting'));
  })
  .catch(err => Promise.reject(err));