import * as React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getCertificate } from "../apiCall"

const TestIndex = ({ data, location }) => {
    const [ k , setk] = React.useState('');
    const [ naes , setn] = React.useState('');
    const names = "testing"
    const imagio = "https://via.placeholder.com/150/92c952"
    React.useEffect(() => {
        const h = location.hash.substring(1);
        const id = h.split("=")[1];
        getCertificate(id)
        .then( (res) => {
            console.log(res);
            setk(imagio);
            setn("teserract")
        })
        // setk();
        console.log(k);
    }, [])
  return (
    <div>
        <Seo title={naes} description="teee"/>
        {/* <Helmet>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@dezervHQ" />
            <meta name="twitter:title" content={naes} />
            <meta name="twitter:description" content="Just invested on dezerv. Sign up today to get access to such opportunities!" />
            <meta
            name="twitter:image"
            content={k}
            />
        </Helmet> */}
        Hello world
        {k}
    </div>
  )
}

export default TestIndex
